import React from "react";
import { useState } from "react";

function Slider() {
    const [domainvalue, setDomainvalue] = useState('');

    const handleInputChange = (event) => {
        setDomainvalue(event.target.value);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        const baseUrl = window.location.origin + '/billing/cart.php';

        const query = new URLSearchParams({
            a: 'add',
            domain: 'register', // Modify as needed
            query: domainvalue,
        }).toString();

        window.location.href = baseUrl + '?' + query;
    }

    return (
        <section id="slider">
            <div className="slider-wrapper domain-slider">
                <div className="animation">
                    <div className="ani-item"></div>
                    <div className="ani-item"></div>
                    <div className="ani-item"></div>
                    <div className="ani-item"></div>
                    <div className="ani-item"></div>
                    <div className="ani-item"></div>
                    <div className="ani-item"></div>
                </div>
                <div className="inner-content">
                    <div className="item">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-sm-12 col-md-6 col-lg-6">
                                    <img src="/web_images/banner-3.webp" alt="hosting banner" className="img-fluid" />
                                </div>
                                <div className="col-sm-12 col-md-6 col-lg-6">
                                    <h1 className="title">Search for Your Domain Name Instantly!.</h1>
                                    <p className="text">Stand out from the competition with a domain that showcases your business name and brand. Secure your online presence today!.</p>

                                    <form className="slider domain-search-form" onSubmit={handleSubmit} id="frmDomainHomepage">
                                        <input type="text"
                                            className="form-control"
                                            name="domain"
                                            value={domainvalue}
                                            placeholder="eg. example.com"
                                            autoCapitalize="none"
                                            onChange={handleInputChange}
                                            required
                                            data-toggle="tooltip"
                                            data-placement="left"
                                            data-trigger="manual"
                                            title="Required" />
                                        <button className="btn domain-search-btn" type="submit">
                                            <span className="spinner me-2"><i className="fa-solid fa-search"></i></span>
                                            <span className="button-text">search</span>
                                        </button>
                                    </form>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Slider;