import React from "react";
import { Helmet } from "react-helmet";
import { Outlet } from "react-router-dom";
import useGoogleApi from "../dbconfig/useGoogleApi";
import Header from "./Header.js";
import "../../assets/css/constant.css";
import "../../assets/css/header.css";
import "../../assets/css/homepage.css";
import "../../assets/css/contact.css";
import "../../assets/css/keyframes.css";
import "../../assets/css/media.css";
import "../../assets/js/validation.js";
import Footer from "./Footer.js";
import Alert from "../../components/views/Errors/Alert.js";
function Master() {
    const { analytics, site_code, places } = useGoogleApi();
    return (
        <div>
            <Helmet>
                <html lang="en"></html>
                <title>Limahosting developer</title>
                <meta name="title" content=""/>
                <meta name="description" content=""/>
                <meta name="keywords" content=""/>
                <meta name="google-site-verification" content={`${site_code}`}/>
                <meta property="og:image" content=""></meta>
                <meta property="og:url" content={window.location.origin}></meta>
                <meta name="canonical" content={window.location.origin}></meta>
                <script async={`https://maps.googleapis.com/maps/api/js?key=${places}&libraries=places`}></script>
                <script async src={`https://www.googletagmanager.com/gtag/js?id=${analytics}`}></script>
                <script>
                    {
                        `window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
    
                    gtag('config', '${analytics}');`
                    }
                </script>
            </Helmet>
            <Header />
            <Alert></Alert>
            <Outlet></Outlet>
            <Footer />
        </div>
    );
}
export default Master;