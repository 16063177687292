import React from "react";
import { Link } from "react-router-dom";

function InfoRight()
{
        return (
            <section className="info-wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-6 col-lg-6">
                            <div className="content">
                            <img src="/web_images/power-button.webp" alt="power button" className="img-fluid" loading="lazy"/>
                                <h3 className="title">Experience Unmatched Reliability with Our Servers!</h3>
                                <p className="text">Our state-of-the-art servers are built for performance and stability, ensuring your website is always online. With a guaranteed uptime of 99.9%, you can trust us to keep your business running smoothly, no matter the demand.</p>
                                <h3 className="title">Lightning-Fast Speeds for Optimal Performance!</h3>
                                <p className="text">Enjoy ultra-fast load times with our high-performance servers, designed to handle heavy traffic and resource-intensive applications. Boost your user experience and keep your visitors engaged with seamless performance every time.</p>
                                <h3 className="title">Top-Tier Security for Your Peace of Mind!</h3>
                                <p className="text">Protect your data with our advanced security features, including DDoS protection, firewalls, and regular backups. Our servers are equipped with the latest security protocols, ensuring your business remains safe from threats while you focus on growth.</p>
                            </div>
                            <div className="action-wrapper">
                                <Link href="#" className="link">get started</Link>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6">
                        <img src="/web_images/lima-hosting-hero.webp" alt="Hosting hero" className="img-fluid" loading="lazy"/>
                        </div>
                    </div>
                </div>
            </section>
        );
}

export default InfoRight