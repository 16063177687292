import React from "react";
import SitePrefference from "../../dbconfig/SitePrefference";

function Chosen() {
    const settingData = SitePrefference();

    return (
        <section className="choosen-template">
            <div className="header">
            {
                   settingData && settingData.details &&(
                    <p className="text-title">{settingData.details.site_name}</p>
                   )
                }

                <h1 className="title">why <span className="colorful">should you choose us?</span></h1>
            </div>
            <div className="card-wrapper">
                <div className="container">
                    <div className="card">
                        <img className="img-fluid" src="/web_images/secure-hosting.webp" alt="security" loading="lazy" />
                        <div className="card-body">
                            <h1 className="title">security</h1>
                            <p className="text">Our AI firewall utilizes advanced machine learning techniques to analyze
                                network traffic and block malicious activity in real time,
                                providing an extra layer of security for your virtual private server.</p>
                        </div>
                    </div>
                    <div className="card">
                        <img className="img-fluid" src="/web_images/ssl-side.webp" alt="ssl" loading="lazy" />
                        <div className="card-body">
                            <h2 className="title">ssl</h2>
                            <p className="text">We provide free SSL certificate and this let you to encrypts your website
                                content and let your visitors know that you website is trustworthy and secure.</p>
                        </div>
                    </div>
                    <div className="card">
                        <img className="img-fluid" src="/web_images/hosting-server.webp" alt="high hosting service" />
                        <div className="card-body">
                            <h3 className="title">high speed server</h3>
                            <p className="text">Top-brand servers with high-quality Intel
                                CPUs and RAM for consistently high performance on web content delivery.</p>
                        </div>
                    </div>
                    <div className="card">
                        <img className="img-fluid" src="/web_images/web_design.webp" alt="cloud hosting" />
                        <div className="card-body">
                            <h4 className="title">cloud hosting</h4>
                            <p className="text">Create your website on a virtual network which allow your website to run smoothly with
                                scalable resources and enhanced reliability.
                            </p>
                        </div>
                    </div>
                    <div className="card">
                        <img className="img-fluid" src="/web_images/business_email.webp" alt="business email" />
                        <div className="card-body">
                            <h5 className="title">business email</h5>
                            <p className="text">Get professional email service that uses your company's domain name, enhancing your brand's credibility.
                            </p>
                        </div>
                    </div>
                    <div className="card">
                        <img className="img-fluid" src="/web_images/support.webp" alt="support" />
                        <div className="card-body">
                            <h6 className="title">worry about getting support?</h6>
                            <p className="text">We have real human to assist you in times of trouble and worries. Just hit on the support button and
                                we will be glad to answer your enquiry within short period
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Chosen;