import React, { useEffect, useState } from "react";
import Slider from "./Slider";
import About from "./About";
import SharedFaq from "./SharedFaq";
import FeaturePricing from "./FeaturePricing";
import {ProductProvider} from "../../context/ProductContext";

function SharedHosting()
{
    return (
        <ProductProvider>
            <section id="homepage">
                    <Slider/>
                    <FeaturePricing/>
                    <About/>
                    <SharedFaq/>
            </section>
        </ProductProvider>
    );
}

export default SharedHosting;