import React, { useState } from "react";

function WhatWeOffer() {

    const [activeTab, setActiveTab] = useState('web-design');
    const toggleWebOffer = (tabContentID) => {
        setActiveTab(activeTab === tabContentID ? false : tabContentID);

    }
    return (
        <section className="web_offer">
            <div className="animation">
                <div className="ani-item"></div>
                <div className="ani-item"></div>
                <div className="ani-item"></div>
                <div className="ani-item"></div>
                <div className="ani-item"></div>
                <div className="ani-item"></div>
                <div className="ani-item"></div>
            </div>
            <div className="inner-content">
                <h2 className="title">What we offer.</h2>
                <div className="container">
                    <div className="tab">
                        <div className="tab-header">
                            <ul className="menu-header">
                                <li className={activeTab === 'web-design' ? 'menu-item active' : 'menu-item'}>
                                    <button className="btn web-design" onClick={() => toggleWebOffer('web-design')}>
                                        <span>
                                            <img className="img-fluid" alt="web-design" src="/web_images/web_design.webp" loading="lazy" />
                                        </span>
                                        design</button>
                                </li>
                                <li className={activeTab === 'marketing-tools' ? 'menu-item active' : 'menu-item'}>
                                    <button className="btn marketing-tools" onClick={() => toggleWebOffer('marketing-tools')}>
                                        <span>
                                            <img className="img-fluid" alt="marketing tool" src="/web_images/marketing_tool.webp" loading="lazy" />
                                        </span>
                                        marketing tools</button>
                                </li>
                                <li className={activeTab === 'seo' ? 'menu-item active' : 'menu-item'}>
                                    <button className="btn seo" onClick={() => toggleWebOffer('seo')}>
                                        <span>
                                            <img className="img-fluid" alt="seo" src="/web_images/seo.webp" loading="lazy" />
                                        </span>
                                        google seo</button>
                                </li>

                                <li className={activeTab === 'softwares' ? 'menu-item active' : 'menu-item'}>
                                    <button className="btn softwares" onClick={() => toggleWebOffer('softwares')}>
                                        <span>
                                            <img className="img-fluid" alt="accounting software" src="/web_images/accounting-software.webp" loading="lazy" />
                                        </span>
                                        business softwares</button>
                                </li>
                            </ul>
                        </div>
                        <div className="tab-content">
                            <div className="container">
                                <div className="row">
                                    <div className="col-sm-12 col-md-6 col-lg-6">
                                        <div>
                                            <img src="/web_images/cloud.webp" className="img-fluid" alt="cloud icon" loading="lazy" />
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-lg-6">
                                        <div id="web-design" className={activeTab === 'web-design' ? 'content active' : 'content'}>
                                            <h4 className="title">Amazing SEO website for your business</h4>
                                            <p className="text">
                                                We’re excited to help you elevate your online presence! Our team specializes in creating stunning SEO-optimized websites tailored to your business needs. With a focus on design, functionality, and search engine visibility,
                                                we ensure your site not only looks great but also attracts more customers.
                                            </p>
                                        </div>
                                        <div id="marketing-tools" className={activeTab === 'marketing-tools' ? 'content active' : 'content'}>
                                            <h4 className="title">Unlock Your Business Potential with Ready-Made Marketing Tools!</h4>
                                            <p className="text">
                                                Discover our comprehensive suite of marketing tools designed to accelerate your business growth. From customizable templates to automated email campaigns, we provide everything you need to enhance your marketing strategy, engage your audience,
                                                and drive sales. Get started today and watch your business thrive!
                                            </p>
                                        </div>
                                        <div id="seo" className={activeTab === 'seo' ? 'content active' : 'content'}>
                                            <h4 className="title">Maximize Your Online Presence with Our SEO Tools!</h4>
                                            <p className="text">
                                                Our ready-made SEO tools are designed to help your business grow by improving your search engine rankings and visibility. From keyword research to on-page optimization and backlink analysis, we provide everything you need to enhance your online strategy.
                                                Start attracting more traffic and converting visitors into customers today!
                                            </p>
                                        </div>
                                        <div id="softwares" className={activeTab === 'softwares' ? 'content active' : 'content'}>
                                            <h4 className="title">Streamline Your Business with Our Invoicing and Payroll Software!</h4>
                                            <p className="text">
                                                Our user-friendly invoicing and payroll systems simplify your financial management. Generate professional invoices, track payments, and manage your payroll effortlessly—all in one place. Save time, reduce errors, and ensure compliance with ease.
                                                Elevate your business operations and focus on what truly matters—growing your business!
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default WhatWeOffer;