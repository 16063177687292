import React, { useEffect } from "react";
import {ProductProvider} from "../../context/ProductContext";
import Slider from "../Products/Slider";
import FeaturePricing from "../Products/FeaturePricing";
import About from "../Products/About";
import WordpressFaq from "../Products/WordpressFaq";

function WordpressHosting()
{
    return (
        <ProductProvider>
            <section id="homepage">
                <>
                    <Slider/>
                    <FeaturePricing/>
                    <About/>
                    <WordpressFaq/>
                </>
            </section>
        </ProductProvider>
    );
}

export default WordpressHosting;