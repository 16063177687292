import { Helmet } from "react-helmet";
import SitePrefference from "./../../dbconfig/SitePrefference";
import Slider from "./Slider";
import Domain from "./Domain";
import Chosen from "./Chosen";
import Info from "./Info";
import InfoRight from "./InfoRight";
import Newsletter from "./Newsletter";
import "../../../assets/css/homepage.css";
import WhatWeOffer from "./WhatWeOffer";
import Popup from "../extra/Popup";
function Home() {
    const settingParam = SitePrefference();

    const data = {
        "title_option" : settingParam && settingParam.setting ? settingParam.setting.title_option : null,
        "title" : settingParam && settingParam.seo ? settingParam.seo.title : null,
        "name" : settingParam && settingParam.details ? settingParam.details.site_name : null,
        "description" : settingParam && settingParam.seo ? settingParam.seo.description : null,
        "keywords" : settingParam && settingParam.seo ? settingParam.seo.keywords : null,
    }

    return (
     <section id="homepage">
      <Helmet>
        if(data.title_option === 1) 
        {
            <title>{data.title + ' - '+ data.name}</title>
        }else{
            <title>{data.title}</title>
        }

        <meta name="description" content={`${data.description}`}></meta>
        <meta name="keywords" content={`${data.keywords}`}></meta>
      </Helmet>
      <Popup></Popup>
      <Slider/>
      <Domain/>
      <Chosen/>
      <WhatWeOffer/>
      <Info/>
      <InfoRight/>
      <Newsletter/>
     </section>
    )
}

export default Home;