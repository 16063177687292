import { useEffect, useState } from "react"

const Menu = () => {
    const [menus, setMenus] = useState(null);

    useEffect(()=>{
           
        const url = process.env.REACT_APP_API_URL;
       
        const fetchSiteMenu = async () => {
            try{

                const response = await fetch(url+'/api/menus/fetch',{
                        "content-type" : "application/json",
                        "Accept" : "application/json",
                        "Cache-Control" : "no-cache"
                });

                if(!response.ok)
                    {
                        console.log("Failed to fetch categories:", response.statusText)
                    }
    
                    let result = await response.json();

                   if(result.status === 200)
                   {
                    
                        setMenus(result.menus)
                   }
            }catch(error)
            {
                console.log("Failed to fetch categories:", error)
            }
        };
        fetchSiteMenu();
    }, []);

    return menus;
}

export default Menu;