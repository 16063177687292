import React from "react";
import {createBrowserRouter} from "react-router-dom";
import Master from "../components/layouts/Master";
import Home from "../components/views/homepage/Home";
import DomainChecker from "../components/views/domain/DomainChecker";
import WordpressHosting from "../components/views/Products/WordpressHosting";
import SharedHosting from "../components/views/Products/SharedHosting";
import Design from "../components/views/Products/Design";
import NoPage from "../components/views/Errors/NoPage";
import NoResult from "../components/views/Errors/NoResult";
import ArticlePage from "../components/views/extra/ArticlePage";
import Contact from "../components/views/extra/Contact";


 const Router = createBrowserRouter([
     {
         path : "/",
         element : <Master/>,
         children : [
             {
                 path : "/",
                 element : <Home/>
             },
             {
                 path:"/domains",
                 element:<DomainChecker/>
             },
             {
                 path:"/cpanel-hosting/:category",
                 element: <SharedHosting/>
             },
             {
                 path:"/wordpress-hosting/:category",
                 element: <WordpressHosting/>
             },
             {
                 path:"/web-design-hosting/:category",
                 element: <Design/>
             },
             {
                 path:"/no-product",
                 element: <NoResult/>
             },
             {
                 path : "*",
                 element: <NoPage/>
             },
             {
                 path : "/error-404",
                 element: <NoPage/>
             },
             {
                 path : "/article/:page",
                 element: <ArticlePage/>
             },
             {
                 path : "/contact",
                 element: <Contact/>
             }
         ]
     }
 ]);

 export default Router;

