import { useEffect, useState } from "react";
import "../../../assets/css/popup.css";
function Popup()
{
            const [isPopupDisable, setisPopupDisable] = useState(false);

            useEffect(()=>{
                const activePopup = localStorage.getItem("popup");
               
                if(activePopup && activePopup === "true")
                {
                    setisPopupDisable(true);
                }

            
            }, []);

            const disablePopup = () => {
                localStorage.setItem("popup", "true");
                setisPopupDisable(true);
            }

           
    return (
        <div className={`popup-container ${isPopupDisable ? "hide-popup" : ""}`}>
            <div className="inner-content">
                <button className="btn cancel-popup-btn" onClick={disablePopup}><i className="fa-solid fa-xmark"></i></button>
                <div className="image-wrapper">
                    <a href="/domains" className="link" rel="noopenner noreferrer" onClick={disablePopup}>
                    <img src="/web_images/domain_sales_offer.webp" className="img-fluid"/>
                    </a>
                </div>
            </div>
        </div>
    );
}

export default Popup;