import { useEffect, useState } from "react"

const HeaderCategory = () => {
    const [category, setCategory] = useState(null);

    useEffect(()=>{
           
        const url = process.env.REACT_APP_API_URL;
       
        const fetchcategory = async () => {
            try{

                const response = await fetch(url+'/api/products/category',{
                        "content-type" : "application/json",
                        "Accept" : "application/json",
                        "Cache-Control" : "no-cache"
                });

                if(!response.ok)
                    {
                        console.log("Failed to fetch categories:", response.statusText)
                    }
    
                    let result = await response.json();

                   if(result.status === 200)
                   {
                    
                        setCategory(result.data)
                   }
            }catch(error)
            {
                console.log("Failed to fetch categories:", error)
            }
        };
        fetchcategory();
    }, []);

    return category;
}

export default HeaderCategory;