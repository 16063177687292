import React from "react";

function About()
{
    return (
        <section className="about-product">
            <div className="container">
                <h1 className="title">Our recent projects and templates</h1>
                <div className="card-group">
                    <div className="card">
                        <div className="card-header">
                            <img src="/web_images/auto_repair_site.webp" alt="auto repair" className="img-fluid"/>
                        </div>
                        <div className="card-body">
                            <h4 className="title">Auto Repair website.</h4>
                            <p className="text">Are you looking to increase your customer sales?
                                Our auto repair website is designed to help you do just that!
                                With features that enhance user experience, streamline booking processes,
                                and showcase your services,
                                you can attract more customers and retain existing ones.</p>
                        </div>
                        <div className="card-footer">
                            <a href="https://auto.bigeweb.com" className="btn preview" target="_blank">
                                <span className="icon"><i className="fa-solid fa-eye"></i></span>
                                <span className="text">preview</span>
                            </a>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header">
                            <img src="/web_images/window_blind_website.webp" alt="window blind" className="img-fluid"/>
                        </div>
                        <div className="card-body">
                            <h4 className="title">window blind website.</h4>
                            <p className="text">Increase your customer sales? Our window shade website is the perfect
                                solution!
                                Designed to showcase your products beautifully and make the shopping experience
                                seamless,
                                you can attract more customers and drive sales.</p>
                        </div>
                        <div className="card-footer">
                            <a href="https://shades.bigeweb.com" className="btn preview" target="_blank">
                                <span className="icon"><i className="fa-solid fa-eye"></i></span>
                                <span className="text">preview</span>
                            </a>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header">
                            <img src="/web_images/hosting_website.webp"  alt="hosting website image" className="img-fluid"/>
                        </div>
                        <div className="card-body">
                            <h4 className="title">Hosting website.</h4>
                            <p className="text">Our hosting website offers robust and reliable hosting solutions designed to meet all your needs.
                                Whether you’re a small business,
                                an e-commerce store, or a developer, we have the perfect plan for you! .</p>
                        </div>
                        <div className="card-footer">
                            <a href={window.location.origin} className="btn preview" target="_blank">
                                <span className="icon"><i className="fa-solid fa-eye"></i></span>
                                <span className="text">preview</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default About;