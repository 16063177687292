import React from "react";
import '../../../assets/css/homepage.css';
import '../../../assets/css/domain.css';
import { Helmet } from "react-helmet";
import Slider from "./Slider";
import DomainSelector from "./DomainSelector";
import Ads from "./Ads";
import Feature from "./Feature";
import Faq from "./Faq";
import SitePrefference from "../../dbconfig/SitePrefference";

function DomainChecker()
{
    const settingParam = SitePrefference();

    const data = {
        "name" : settingParam && settingParam.details ? settingParam.details.site_name : null,
    }


    return (
        <section id="homepage">
            <Helmet>
        if(data.title_option === 1) 
        {
            <title>{"Discover Available Domain Names | "+data.name + ' Domain Finder - '+ data.name}</title>
        }else{
            <title>{"Discover Available Domain Names | "+data.name + ' Domain Finder'}</title>
        }

        <meta name="description" content={`Looking for a domain name? "+ ${data.name} +" makes it simple! Search our extensive database to find and register the ideal domain for your business or personal project. Start your search now!`}></meta>
        <meta name="keywords" content={`buy domain names, premium domains, domain registration, cheap domain names, available domains, best domain registrars, SEO-friendly domains, domain search tool, domain name generator, how to buy a domain, domain marketplace, domain auctions, domain investment, website domain names, short domain names, buy expired domains, .com domains, top-level domains, domain name availability, find domain names, brandable domain names
                                `}></meta>
      </Helmet>
    <Slider />
    <DomainSelector />
    <Feature />
    <Ads />
    <Faq />
    </section>
    );
}
export default DomainChecker