import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import Router from "./Routers/Router";

let mainContainer = document.getElementById("limahosting");
if (mainContainer) {
  let root = ReactDOM.createRoot(mainContainer);
  if (root) {
    root.render(
    <RouterProvider router={Router}></RouterProvider>
    );
  }
}