import {React, useState} from "react";
import {useProduct} from "../../context/ProductContext";

function FeaturePricing()
{

    const [loadMoreTable, setloadMoreTable] = useState(false);

    const HandleLoadFeature = () =>{
        setloadMoreTable(!loadMoreTable);
    }

    const products = useProduct();
    const [interval, setInterval] = useState('quarterly');

    const HandlePaymentPlan = (event) =>{
        let planInterval = event.target.value;
        setInterval(planInterval);
    }

    const getLocation = () =>
    {
        let url = window.location.origin;
        url = url.replace(/^(http:\/\/|https:\/\/)/, '');
       url = url.replace('www.', '');
        let splitURL = url.split('.');
        if(splitURL.pop !== 'com')
        {
            return (splitURL[0])
        }else if(splitURL[0] !== 'com')
        {
            return (splitURL[0])
        }else{
            return false;
        }
    };


        const priceBasedOnLocation = (amount) => {
            let location = getLocation();

            const increment_rate = {
                'sg': 3.2,
                'uk': 5.6,
            }
            let price = parseFloat(amount);
            switch (location) {
                case 'sg' :
                    price = amount * 2;
                    price = price /increment_rate['sg'];
                    break;
                case 'uk' :
                    price = amount * 2;
                    price = price /increment_rate['uk'];
                    break;
            }

            return (price.toFixed(2));
        }

    const getCurrenySymbol = () => {
        let location = getLocation();
        if(location !== false) {
            const currencySymbols = {
                USD: '$',
                EUR: '€',
                GBP: '£',
                SGD: 'S$', // Add other currencies as needed
            };
            if (location) {
                switch (location) {
                    case 'sg':
                        return currencySymbols['SGD'];
                    case 'uk':
                        return currencySymbols['GBP'];
                    default:
                        return products.currency_symbol; // Fallback to default
                }
            }
            return products.currency_symbol;
        }
        }


    return (
        <section id="pdt-section">
            <div className="container">
                <div className="button-wrapper">
                    <button className={`btn ${interval === 'monthly' ? 'active' : null}`} onClick={HandlePaymentPlan} value="monthly">monthly</button>
                    <button className={`btn ${interval === 'quarterly' ? 'active' : null}`}  onClick={HandlePaymentPlan} value="quarterly">quarterly</button>
                    <button className={`btn ${interval === 'yearly' ? 'active' : null}`}  onClick={HandlePaymentPlan} value="yearly">yearly</button>
                </div>
                <div className="content-wrapper">
                    <p className="text">Pricing Plans</p>
                    <h3 className="title">SELECT BEST PLAN</h3>
                    <div className="pl-wrapper-grid">
                        <div className="card pl-card">
                            <div className="card-body">
                                <img src="/web_images/database.webp" alt="database" className="img-fluid"/>
                            </div>
                        </div>
                        {
                            products.product ? (
                                products.product.map(item => (
                                    <div className="card pl-card">
                                        <div className="card-header">
                                            <h3 className="min-title">{item.name}
                                                {
                                                    item.is_recommended === '1' ? (
                                                        <>
                                                            <i className="fa-solid fa-minus me-1 ms-1"></i>
                                                            <span className="badge bg-danger rec">recommended</span>
                                                        </>
                                                    ) : null
                                                }
                                            </h3>
                                            <p className="text">{item.tagline}</p>
                                            <p className="price">
                                                {
                                                    item.plan.map(plan_price => (
                                                        plan_price.payment_interval === interval ? (
                                                            <>
                                                                <p className="price">
                                                                    <sup>{getCurrenySymbol()}</sup>
                                                                    <span
                                                                        className="value">{priceBasedOnLocation(plan_price.price)}</span>
                                                                    <span
                                                                        className="text">/Month</span>
                                                                </p>
                                                                {
                                                                    plan_price.setup_fee > 0 ? (
                                                                        <p className="custom-price">
                                                                            <span className="label">setup fee:</span>
                                                                            <span
                                                                                className="value">{getCurrenySymbol() + priceBasedOnLocation(plan_price.setup_fee)}</span>
                                                                        </p>
                                                                    ) : null
                                                                }
                                                            </>
                                                        ) : null
                                                    ))
                                                }
                                            </p>
                                        </div>
                                        <div className="card-body">
                                            {
                                                item.description ? (
                                                    <div className="desc"
                                                         dangerouslySetInnerHTML={{__html: item.description}}></div>
                                                ) : null
                                            }
                                        </div>
                                        <div className="card-footer">
                                            <a href={`https://${item.action_link}`}
                                               className="link btn">{item.action_type}</a>
                                        </div>
                                    </div>
                                ))
                            ) : null
                        }
                    </div>
                    <div className="act-button-wrapper text-center">
                        <button className="btn" onClick={HandleLoadFeature}>view Specification</button>
                    </div>
                    <div className="table-responsive">
                        <div className="product-feature-table-wrapper">
                            <div className={loadMoreTable === true ? 'active content-table' : 'content-table'}>
                                <div className="product-feature-wrapper"
                                     dangerouslySetInnerHTML={{__html: products.feature}}></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );

}

export default FeaturePricing;