import { useEffect, useState } from "react"

const useGoogleApi = () => {
    const [googleApi, setGoogleApi] = useState({
        analytics: "",
        site_code: "",
        places: ""
    });

    useEffect(()=>{
           
        const url = process.env.REACT_APP_API_URL;
       
        const fetchGoogleApiData = async () => {
            try{

                const response = await fetch(url+'/api/system-details',{
                        "content-type" : "application/json",
                        "Accept" : "application/json",
                        "Cache-Control" : "no-cache"
                });

                if(!response.ok)
                    {
                        console.log("Failed to fetch google api credential:", response.statusText)
                    }
    
                    let result = await response.json();

                   if(result.status === 200)
                   {
                    
                        setGoogleApi({
                            analytics: result.googleApi.g_analytics,
                            site_code: result.googleApi.gsite_verification,
                            places: result.googleApi.g_places,
                        })
                   }
            }catch(error)
            {
                console.log("Failed to fetch google api credential:", error)
            }
        };
        fetchGoogleApiData();
    }, []);

    return googleApi;
}

export default useGoogleApi;