import { useEffect, useState } from "react"

const SitePrefference = () =>{
    const [settings, setSettings] = useState(null);


    useEffect(() =>{
        
        const url =  process.env.REACT_APP_API_URL;

        const fetchSetting = async () => {
           try{
                const response = await fetch(url+'/api/system-details',{
                    "content-type" : "application/json",
                    "Accept" : "application/json",
                    "Cache-Control" : "no-cache"
                    });

                    if(!response.ok)
                        {
                            console.log("Failed to fetch site settings credential:", response.statusText)
                        }
        
                        let result = await response.json();
    
                       if(result.status === 200)
                       {
                        
                          setSettings({
                            "setting" : result.advance_option,
                            "seo" : result.seo,
                            "details" : result.system_details
                          })
                       }

           }catch(error)
           {
            console.log("Failed to fetch site settings credential:", error)
           }
        };
        fetchSetting();
    }, [])

   return (settings);
}


export default SitePrefference;