function Slider()
{

    return (
        <section id="slider">
        <div className="slider-wrapper">
            <div className="animation">
                <div className="ani-item"></div>
                <div className="ani-item"></div>
                <div className="ani-item"></div>
                <div className="ani-item"></div>
                <div className="ani-item"></div>
                <div className="ani-item"></div>
                <div className="ani-item"></div>
            </div>
            <div className="inner-content">
                <div className="item">
                   <div className="container">
                   <div className="row justify-content-center">
                   <div className="col-sm-12 col-md-6 col-lg-6">
                   <img src="/web_images/Hosting banner.webp"  alt="hosting banner" className="img-fluid" loading="lazy"/>
                    </div>
                   <div className="col-sm-12 col-md-6 col-lg-6">
                        <h1 className="title">RM4.90/month <br></br> Supper fast ssd hosting plan.</h1>
                        <h2 className="text">Unlock Your Online Potential with Our Lightning-Fast Web Hosting, Backed by 99.9% Uptime and 24/7 Expert Support!</h2>
                    <div className="button-group">
                        <a className="btn action-btn" href={'/cpanel-hosting/cpanel-hosting'}>get started</a>
                        <a className="btn action-btn" href={'/wordpress-hosting/wordpress-hosting'}>learn more</a>
                    </div>
                    </div>
                   </div>
                   </div>
                </div>
            </div>
        </div>
    </section>
    )
}

export default Slider;