import { useEffect, useState } from "react";
import AppearancePrefference from "../dbconfig/AppearancePrefference";
import SitePrefference from "../dbconfig/SitePrefference";
import { Helmet } from "react-helmet";
import HeaderCategory from "../dbconfig/HeaderCategory";

function Header() {
    const settingParam = SitePrefference();
    const appearanceOption = AppearancePrefference();

    const defaultData = {
        hotline: settingParam ? settingParam.setting.hotline : null,
        email: settingParam ? settingParam.setting.email : null,
        logo: appearanceOption ? process.env.REACT_APP_API_URL + '/' + appearanceOption.logo : null,
        favicon: appearanceOption ? process.env.REACT_APP_API_URL + '/' + appearanceOption.favicon : null
    }

    const categories = HeaderCategory();

    const [active, setActive] = useState(false);
    const toggleMenu = () => {
        setActive(!active);
    };

    //For the product categories
    const [activeDropdown, setActiveDropdown] = useState(null);

    const toggleDropdownMenu = (dropdownID) => {
        setActiveDropdown(activeDropdown === dropdownID ? false : dropdownID);
    };

    useEffect(() => {
        document.addEventListener("click", (event) => {
            var dropdownsElement = document.querySelectorAll(".item.menu-dropdown");
            if (dropdownsElement.length > 0) {
                let DropdownClicked = false;
                dropdownsElement.forEach((dropdown) => {
                    if (dropdown.contains(event.target)) {
                        DropdownClicked = true;
                    }
                });

                if (!DropdownClicked) {
                    dropdownsElement.forEach((dropdown) => {
                        if (dropdown.classList.contains("active")) {
                            dropdown.classList.remove("active");
                        }
                    });
                }
            }

        });
    }, []);

    return (
        <header id="header">
            <Helmet>
                <meta property="og:image" content={`${defaultData.favicon}`}></meta>
                <link rel="icon" type="image/x-icon" href={`${defaultData.favicon}`}></link>
            </Helmet>
            <div className="header-top">
                <div className="container">
                    <div className="flex-container">
                        <ul className="flex-item-left flex-item">
                            <li className="item">
                                <a href={`tel:${defaultData.hotline}`} className="link" aria-label="hotline" rel="noopener noreferrer">
                                    <span className="icon"><i className="fa-solid fa-phone-volume"></i></span>
                                    <span className="text">{defaultData.hotline}</span>
                                </a>
                            </li>
                            <li className="item">
                                <a href={`mailto:${defaultData.email}`} className="link" aria-label="email us" rel="noopener noreferrer">
                                    <span className="icon"><i className="fa-solid fa-envelope"></i></span>
                                    <span className="text text-lowercase">{defaultData.email}</span>
                                </a>
                            </li>
                        </ul>
                        <ul className="flex-item-right flex-item">
                            <li className="item">
                                <a href="https://limahosting.my/billing/index.php?rp=/announcements" className="link" aria-label="announcement" rel="noopener noreferrer">
                                    <span className="icon"><i className="fa-solid fa-bullhorn"></i></span>
                                    <span className="text"> announcement</span>
                                </a>
                            </li>
                            <li className="item">
                                <a href="https://limahosting.my/billing/index.php?rp=/knowledgebase" className="link" aria-label="knowledge base" rel="noopener noreferrer">
                                    <span className="icon"><i className="fa-brands fa-firefox-browser"></i></span>
                                    <span className="text"> knowledgebase</span>
                                </a>
                            </li>
                            <li className="item">
                                <a href="https://limahosting.my/billing/cart.php?a=view" className="link" aria-label="cart" rel="noopener noreferrer">
                                    <span className="icon"><i className="fa-solid fa-cart-shopping"></i></span>
                                    <span className="text">  cart</span>
                                </a>
                            </li>
                            <li className="item">
                                <a href={`https://wa.me/${defaultData.hotline}`} className="link" target="_blank" aria-label="whatsapp us" rel="noopener noreferrer">
                                    <span className="icon"><i className="fa-brands fa-whatsapp"></i></span>
                                    <span className="text">
                                        whatsApp
                                    </span>
                                </a>
                            </li>
                            <li className="item">
                                <a href="https://limahosting.my/billing/index.php?rp=/login" className="link" aria-label="sign in" rel="noopener noreferrer">
                                    <span className="icon"><i className="fa-solid fa-user-large"></i></span>
                                    <span className="text"> sign in</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            {/* menu start */}
            <div className="navbar" id="header-middle">
                <div className="container">
                    <div className="header-content-wrapper">
                        <div className="logo">

                            {defaultData.logo != null && (
                                <a href="/" rel="noopener noreferrer">
                                    <picture srcSet={defaultData.logo.replace(/\.(png|jpg|jpeg)$/i, '.webp')} type="image/webp">
                                        <img src={defaultData.logo} alt="logo" className="img-fluid" loading="lazy" />
                                    </picture>
                                </a>
                            )}

                            <div className="toggler-wrapper">
                                <button className="btn toggle-header-menu" onClick={toggleMenu} aria-label="open header menu"><i className="fa-solid fa-bars"></i></button>
                            </div>
                        </div>
                        <div className={active ? 'active header-right-content' : 'header-right-content'}>
                            <ul className="menu-list">
                                <li className="item">
                                    <a href="/" className="link" rel="noopener noreferrer">Home</a>
                                </li>
                                <li className="item">
                                    <a href="/domains" className="link" rel="noopener noreferrer">Domains</a>
                                </li>
                                {categories !== null && (
                                    Array.isArray(Object.values(categories)) && Object.values(categories).length > 0 && (
                                        Object.values(categories).map((category, i) => (
                                            <li className={activeDropdown === category.category_name ? 'item menu-dropdown active' : 'item menu-dropdown'} key={i}>
                                                <button className="btn menu-dropdown-btn link"
                                                    onClick={() => toggleDropdownMenu(category.category_name)} aria-label="category" type='button'>
                                                    <span className="text">{category.category_name}</span>
                                                </button>
                                                <div className="menu-dropdown-widget">
                                                    {
                                                        category.products.length > 0 && (
                                                            category.products.map((product_category, k) =>
                                                                product_category.status === "active" && (
                                                                    <a href={`/${product_category.module}-hosting/${product_category.slug}`} className="menu-link" key={k} arial-label={product_category.module} rel="noopener noreferrer">
                                                                        <div className="widget-item">
                                                                            <div className="item-icon">
                                                                                <picture srcSet={process.env.REACT_APP_API_URL + '/' + product_category.icon.replace(/\.(png|jpg|jpeg)$/i, '.webp')} type="image/webp">
                                                                                    <img src={process.env.REACT_APP_API_URL + '/' + product_category.icon} alt="cPanel Hosting" className="img-fluid" loading="lazy" />
                                                                                </picture>
                                                                            </div>
                                                                            <div className="text-wrap">
                                                                                <p className="text">{product_category.name}</p>
                                                                                <small>{product_category.headline}</small>
                                                                            </div>
                                                                        </div>
                                                                    </a>
                                                                )
                                                            )
                                                        )
                                                    }
                                                </div>
                                            </li>
                                        ))
                                    )
                                )}
                                <li className="item">
                                    <a href={'/contact'} className="link" aria-label="contact us">Contact Us</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header;