import Menu from "../dbconfig/Menu";
import SitePrefference from "../dbconfig/SitePrefference";

function Footer() {

    const FooterMenu = Menu();
    const systemOption = SitePrefference();
    const copyright = systemOption ?
     systemOption.setting.copy_right.replace("{copy_right_icon}", " &copy").replace('{date}', new Date().getFullYear()) : null;
    return (
        <section id="footer">
            <div className="animation">
                <div className="ani-item"></div>
                <div className="ani-item"></div>
                <div className="ani-item"></div>
                <div className="ani-item"></div>
                <div className="ani-item"></div>
                <div className="ani-item"></div>
                <div className="ani-item"></div>
            </div>
            <div className="footer">
                <div className="container">
                    <div className="row g-3">
                        <div className="col-sm-12 col-md-4 col-lg-3">
                            <div className="store-wrap">
                                <h1 className="title">LimaHosting</h1>
                                <p className="text">
                                    We are here to help small, medium and entrepreneur to manage their web services in a fingertip.
                                    are also specialized in building website for any business or personal use. Our project are mobile friendly with weekly update.
                                    You do not have to know much about programing skill. It is easy to use and understandable.
                                    Create your success business website with us
                                </p>
                            </div>
                        </div>
                        {
                            FooterMenu !== null && FooterMenu.length > 0 && (
                                FooterMenu.map((menu, i) => (
                                    menu.status === "active" && (
                                        <div className="col-sm-12 col-md-4 col-lg-3" key={i}>
                                            <div className="footer-menu">
                                                <h1 className="title">{menu.title}</h1>
                                                <ul className="menu-list">
                                                    {
                                                        menu.submenu.length > 0 && (
                                                            menu.submenu.map((menuNode, index) => (
                                                                <li className="item" key={index}>
                                                                    {
                                                                        menuNode.link_type === "page" ? (
                                                                            <a href={`${window.location.origin}/article/${menuNode.url}`}
                                                                                className="link footer-link" target={menuNode.target} dangerouslySetInnerHTML={{ __html: menuNode.title }} rel="noopener noreferrer"></a>
                                                                        ) :
                                                                            <a href={`https://${menuNode.url}`}
                                                                                className="link footer-link" target={menuNode.target} dangerouslySetInnerHTML={{ __html: menuNode.title }} rel="noopener noreferrer"></a>}

                                                                </li>
                                                            ))
                                                        )
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    )
                                ))
                            )
                        }
                    </div>
                </div>
            </div>
            <div className="lower-footer-bottom">
                <div className="container">
                    <div className="row g-3 justify-content-center">
                        <div className="col-sm-12 col-md-4 col-lg-4">
                            <div className="text-center">
                                <div className="text-content-wrap">
                                    <p className="text">follow us on:</p>
                                    <a href="https://www.facebook.com/profile.php?id=100086092347374&mibextid=ZbWKwL" rel="noopener noreferrer"
                                        target="_blank" className="link"><i className="fa-brands fa-square-facebook"></i></a>
                                    <a href="https://twitter.com/" target="_blank" className="link" rel="noopener noreferrer"><i
                                        className="fa-brands fa-twitter"></i></a>
                                    <a href="https://www.instagram.com/" target="_blank" className="link" rel="noopener noreferrer"><i
                                        className="fa-brands fa-instagram"></i></a>
                                    <a href="https://www.youtube.com/channel/UCCciAJQt5dTVvG5rVB7d4aQ" target="_blank" rel="noopener noreferrer"
                                        className="link"><i className="fa-brands fa-youtube"></i></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-4 col-lg-4">
                            <div className="img-wrapper">
                                <p className="text">our payment methods:</p>
                                <img src="/web_images/footer-logo-payment.webp" alt="Web development services payment options logo" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                    <p className="text copyright" dangerouslySetInnerHTML={{ __html:  copyright}}></p>
                </div>
            </div>
        </section>
    )
}

export default Footer;